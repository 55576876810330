import replace from 'lodash/replace';
import get from 'lodash/get';
import split from 'lodash/split';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import { Base64 } from 'js-base64';
import dayjs from 'dayjs';
import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';
import endsWith from 'lodash/endsWith';
import trim from 'lodash/trim';
import size from 'lodash/size';
import { myLoginService } from '@/services/login.service';
import { permissionJpService } from '@/services/permission.service';
import { user } from '@/services/user.service';
import styled from '@emotion/styled';
import { Modal } from '@/components';
import { t } from '@/core/hooks/useTranslation';
export type thousandsFormatType = (num: string | number) => string;

export const thousandsFormat: thousandsFormatType = (num) => {
	if (!num) return '0';

	if (isNaN(+num)) return '0';

	return (
		(+num).toLocaleString('en-us', {
			maximumFractionDigits: 2, // 小数位最大保留位数：0-20
			minimumFractionDigits: 2, // 小数位最小保留位数：0-20
		}) || '0'
	);
};

export type currencyType = (currency: string) => string;

export const getCurrency: currencyType = (currency) => {
	switch (currency) {
		case 'USD':
			return '$';
		case 'INR':
			return '₹';
		case 'SGD':
			return 'S$';
		default:
			return currency;
	}
};

//获取url?后面的参数
export const getUrlParams = (url: string) => {
	const urlStr = url.split('?')[1];
	const urlSearchParams = new URLSearchParams(urlStr);
	const result = Object.fromEntries(urlSearchParams.entries());
	return result;
};

//轮询 exp token
export const errorToken = async () => {
	const noRefresh = false;
	// const data = JSON.parse(myLoginService.getIdentityData());
	// const refreshToken = get(data, 'refreshToken');
	// const res = await myLoginService.refreshToken({  });
	// if (res && get(res, 'code') == '000000') {
	// 	myLoginService.removeIdentityData();
	// 	myLoginService.setIdentityData(
	// 		JSON.stringify(get(get(res, 'data'), 'identity'))
	// 	);
	// } else if (res && get(res, 'code') == '496') {
	// 	noRefresh = true;
	// }
	return noRefresh;
};

const REDUCE_TIME = 0;
const EXP_DATE = 5 * 60;
export const TIME_OUT = 2000 * 60;

export const checkExpToken = async () => {
	let isNoRefresh = false;
	const token: any = get(myLoginService.getGigyaToken(), 'idToken');
	const replaceToken = replace(token, /-/g, '+');
	const replaceToken2 = replace(replaceToken, /_/g, '/');
	const arrToken = split(replaceToken2, '.');
	const exp =
		arrToken &&
		arrToken.length > 0 &&
		JSON.parse(Base64.decode(arrToken[1]))?.exp;

	const expDate = exp - REDUCE_TIME + EXP_DATE;
	// console.log(exp, 'exp');
	// console.log(expDate, '过期时长');
	// console.log(dayjs().unix(), '当前时间戳');

	if (dayjs().unix() >= expDate) {
		const noRefresh = await errorToken();
		console.log('refresh token');
		if (noRefresh) {
			isNoRefresh = true;
		}
	}

	return isNoRefresh;
};

// goto gigya
console.log(
	process.env.REACT_APP_QA,
	',',
	process.env.REACT_APP_CLIENT_ID,
	',',
	process.env.REACT_APP_REDIRECT_URI
);

console.log(
	`https://www.${process.env.REACT_APP_QA}thermofisher.com/identity/api/cip/v1/registration/auth?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}login/callback&scope=openid profile email username hiragananame`,
	'Register'
);
console.log(
	`https://www.${process.env.REACT_APP_QA}thermofisher.com/api-gateway/identity-authorization/identity/oidc/op/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}login/callback&scope=openid profile email username hiragananame`,
	'Login'
);

export const goGigya = () => {
	window.location.href = `https://www.${process.env.REACT_APP_QA}thermofisher.com/api-gateway/identity-authorization/identity/oidc/op/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}login/callback&scope=openid profile email username hiragananame`;
};

export const goGigyaRegister = () => {
	window.location.href = `${process.env.REACT_APP_QA_URL}/identity/api/cip/v1/registration/auth?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}login/callback&scope=openid profile email username hiragananame`;
};

export const logoutGigya = () => {
	user.removeUsercode();
	window.location.href = `https://www.${process.env.REACT_APP_QA
		}thermofisher.com/api-gateway/identity/auth-api/oidc/op/logout?id_token_hint=${myLoginService.getGigyaToken()}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI
		}`;
};

//debounce
let debounceTimer: any = null;
export function debounce(callback: () => void, duration: number) {
	return () => {
		if (debounceTimer) clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => {
			callback();
		}, duration);
	};
}

export const errorCode = [
	499,
	498,
	'498',
	'DP_USER_E_1003',
	'DP_USER_E_1004',
	'496',
	401,
	'499',
	'100024',
	'100025',
	'100026',
];

export const errorResCode = async (code: string | number) => {
	console.log('errorToken==============:', code);
	const codes = [499, '499', 401, '498', 498];
	if (
		includes(codes, code) &&
		!includes(map(publicUrl, toUpper), toUpper(window.location.pathname))
	) {
		myLoginService.removeTokenData();
		window.location.href = '/';
	} else if (code == 'DP_USER_E_1003' || code == '100025') {
		window.location.href = '/login-review';
	} else if (code == 'DP_USER_E_1004' || code == '100026') {
		window.location.href = '/login-inactive';
	} else if (code == 'DP_USER_E_1005' || code == '100024') {
		const loginEmail = myLoginService.getUserRoleUrl();
		window.location.href = loginEmail;
	}
};

/**
 * Highlight the keyword
 * @param value text content
 * @param keyWord keyword
 * @returns The content after reorganization
 */
export const setHighLight = (value: string, keyWord: string) => {
	if (!value || value == '') return '';
	if (value.indexOf(keyWord) !== -1 && keyWord !== '') {
		return value.replace(
			keyWord,
			`<span style="background-color:yellow;padding: 0 0;">${keyWord}</span>`
		);
	}
	return value;
};

export const getMenuTypeId = (
	menu: any,
	parentId: number,
	targetId: number
) => {
	const parentData: any = find(menu, { id: parentId });
	if (parentData && parentData?.contentList) {
		const targetData: any = find(parentData?.contentList, { id: targetId });
		if (targetData && targetData?.contentList) {
			return map(targetData?.contentList, 'id');
		}
	}

	return [];
};

export const getAllThirdLevelIds = (menu: any, parentId: number) => {
	const searchMenu = filter(menu, (item: any) => item?.id == parentId);
	const categoryIdList: number[] = [];
	map(searchMenu, (item) => {
		if (item?.contentList) {
			map(item?.contentList, (item2) => {
				map(item2?.contentList, (item3) => {
					categoryIdList.push(item3?.id);
				});
			});
		}
	});
	return categoryIdList;
};

export const getThirdLevelIds = (
	menu: any,
	parentId: number,
	secondId: number,
	thirdId: number
) => {
	const searchMenu = filter(menu, (item: any) => item?.id == parentId);
	const categoryIdList: number[] = [];
	map(searchMenu, (item) => {
		if (item?.contentList) {
			map(item?.contentList, (item2) => {
				if (item2?.id == secondId) {
					map(item2?.contentList, (item3) => {
						if (item3?.id == thirdId) {
							if (item3?.contentList) {
								map(item3?.contentList, (item4) => {
									categoryIdList.push(item4?.id);
								});
							} else {
								categoryIdList.push(item3?.id);
							}
						}
					});
				}
			});
		}
	});
	return categoryIdList;
};

export const getSecondLevelId = (
	menu: any,
	parentId: number,
	contentType: number
) => {
	const searchMenu = filter(menu, (item: any) => item?.id == parentId);
	let categoryId = 0;
	map(searchMenu, (item) => {
		if (item?.contentList) {
			map(item?.contentList, (item2) => {
				if (item2?.contentType == contentType) {
					categoryId = item2?.id;
				}
			});
		}
	});
	return categoryId;
};

export const getMenuName = (name: string) => {
	switch (name) {
		case 'Notification':
			return 'Notifications';
		case 'Product':
			return 'Products';
		case 'Service':
			return 'Services';
		case 'Help':
			return 'Help';
		default:
			return name;
	}
};

export const changeMenuTypeCode = (
	menu: any,
	categoryName: string,
	productLineId: number
) => {
	const parentItem = find(menu, { id: productLineId });
	if (parentItem && parentItem.contentList) {
		const childItem = find(parentItem.contentList, {
			categoryName: getMenuName(categoryName),
		});
		if (childItem) {
			return childItem.id;
		}
	}
};

export const fileDownload = (
	res: any,
	fileName: string,
	ext: string,
	oths: any = {},
	onOpen?: string
) => {
	let contentType = 'application/pdf';
	if (['xls', 'xlsx'].includes(ext)) {
		contentType = 'application/vnd.ms-excel';
	} else if (['doc', 'docx'].includes(ext)) {
		contentType = 'application/msword';
	} else if (['png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
		contentType = `image/${ext}`;
	} else if (['mp4', 'avi', 'mov', 'mkv'].includes(ext)) {
		contentType = 'video/mp4';
	}

	const blob = new Blob([res], { type: contentType });

	const reader = new FileReader();

	reader.onload = () => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		document.body.appendChild(a);
		if (endsWith(fileName, `.${ext}`)) {
			a.download = fileName;
		} else {
			a.download = `${fileName}.${ext}`;
		}

		a.href = url;
		if (onOpen == '1') {
			a.click();
		} else {
			if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
				window.open(url, '__blank');
			} else {
				a.click();
			}
		}

		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);

		const userBo = !isEmpty(user.getUserBo())
			? JSON.parse(user.getUserBo() as string)
			: {};
		permissionJpService.addContentTrackApi({
			...oths,
			loginEmail: userBo?.loginEmail,
		});
	};
	reader.readAsDataURL(blob);
};
type FormatterType = 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD HH:mm';
export function timestampToTime(
	timestamp: any,
	formatter: FormatterType = 'YYYY-MM-DD',
	append = '-'
) {
	timestamp = timestamp ? timestamp : null;
	// A 10-digit timestamp needs to be *1000, a 13-digit timestamp does not need to be multiplied by 1000（时间戳为10位需*1000，时间戳为13位的话不需乘1000）
	const date = new Date(timestamp);
	const Y = date.getFullYear() + append;
	const M =
		(date.getMonth() + 1 < 10
			? '0' + (date.getMonth() + 1)
			: date.getMonth() + 1) + append;
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	const h =
		(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	const m =
		(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
		':';
	const s =
		date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return formatter === 'YYYY-MM-DD'
		? Y + M + D
		: formatter === 'YYYY-MM-DD HH:mm'
			? Y + M + D + h + m
			: Y + M + D + h + m + s;
}

/**
 * Get the key by Enum value(通过枚举value获取key)
 * @param object Enum Object
 * @param value Enum Value
 * @returns Enum Key
 */
export function getKeyByEnumValue(object: any, value: any) {
	return Object.keys(object).find((key) => object[key] === value);
}

export const VIDEO_TYPES = [
	'mp4',
	'webm',
	'ogg',
	'mkv',
	'avi',
	'mov',
	'wmv',
	'flv',
	'f4v',
	'm4v',
	'rmvb',
	'rm',
	'3gp',
	'mov',
	'mts',
	'vob',
];
export const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif'];
export const OFFICE_TYPES = [
	'doc',
	'docx',
	'xls',
	'xlsx',
	'ppt',
	'pptx',
	'csv',
	'xlsb',
	'xlsm',
	'xltx',
	'pdf',
];

export const EXCEL_TYPES = ['xls', 'xlsx', 'csv', 'xlsb', 'xlsm', 'xltx'];

export const WORD_TYPES = ['doc', 'docx'];

export const APPLICATION_TYPE: any = {
	'1': {
		en: 'Chemical',
		jp: 'ケミカル',
	},
	'2': {
		en: 'Pharmaceutical',
		jp: '製薬',
	},
	'3': {
		en: 'Automotive',
		jp: '自動車',
	},
	'4': {
		en: 'Electronics',
		jp: 'エレクトロニクス',
	},
	'5': {
		en: 'Food & Beverage',
		jp: '食品',
	},
	'6': {
		en: 'Energy',
		jp: 'エネルギー',
	},
	'7': {
		en: 'Forensic',
		jp: '法科学',
	},
	'8': {
		en: 'Environment',
		jp: '環境',
	},
	'9': {
		en: 'Academia',
		jp: 'アカデミア',
	},
	'10': {
		en: 'Evaluation',
		jp: '解析',
	},
	'11': {
		en: 'Hardware',
		jp: 'ハードウェア',
	},
	'12': {
		en: 'Microscope',
		jp: '顕微鏡',
	},
	'13': {
		en: 'Battery',
		jp: 'バッテリー',
	},
	'14': {
		en: 'Gas',
		jp: 'ガス',
	},
	'15': {
		en: 'Polymer',
		jp: 'ポリマー',
	},
	'16': {
		en: 'Others',
		jp: 'その他',
	},
};

export const Scrollbar = styled('div')`
	::-webkit-scrollbar-track-piece {
		-webkit-border-radius: 0;
	}

	@-moz-document url-prefix() {
		scrollbar-width: thin;
		scrollbar-color: #b8b8b8 transparent;
	}
	::-webkit-scrollbar {
		width: 5px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #d3d3d3;
		-webkit-border-radius: 6px;
		outline-offset: -2px;
		filter: alpha(opacity = 50);
		-moz-opacity: 0.5;
		-khtml-opacity: 0.5;
		opacity: 0.5;
	}
	::-webkit-scrollbar-thumb:hover {
		height: 50px;
		background-color: #b8b8b8;
		-webkit-border-radius: 6px;
	}
`;

//browser type
export const getUserAgent = () => {
	const userAgent = navigator.userAgent;
	if (/Edg/.test(userAgent)) {
		return 'Edge';
	} else if (/Firefox/.test(userAgent)) {
		return 'Firefox';
	} else if (/Chrome/.test(userAgent)) {
		return 'Chrome';
	} else if (/Safari/.test(userAgent)) {
		return 'Safari';
	} else if (/MSIE|Trident/.test(userAgent)) {
		return 'IE';
	} else {
		return 'Other';
	}
};

export const getUserAgentInfo = () => {
	const userAgent = navigator.userAgent;

	let osName = '';
	let browserName = '';

	const osList = [
		{ name: 'Windows', regex: /Windows/ },
		{ name: 'Mac OS', regex: /Macintosh/ },
		{ name: 'Linux', regex: /Linux/ },
		{ name: 'iOS', regex: /iPhone|iPad/ },
		{ name: 'Android', regex: /Android/ },
	];

	const browserList = [
		{ name: 'Chrome', regex: /Chrome/ },
		{ name: 'Firefox', regex: /Firefox/ },
		{ name: 'Safari', regex: /Safari/ },
		{ name: 'Edge', regex: /Edg/ },
		{ name: 'IE', regex: /Trident/ },
	];

	for (const os of osList) {
		if (os.regex.test(userAgent)) {
			osName = os.name;
			break;
		}
	}

	for (const browser of browserList) {
		if (browser.regex.test(userAgent)) {
			browserName = browser.name;
			break;
		}
	}
	return `${osName}-${browserName}`;
};

export const getContentTypeName = (contentType: number | string) => {
	switch (contentType) {
		case 0:
			return 'notifications';
		case 1:
			return 'products';
		case 2:
			return 'services';
		case 3:
			return 'help';
		default:
			'';
	}
};

interface LinkTrackType {
	productLineId: string | number;
	contentType: string | number;
	title: string;
	module: string;
}

export const viewLinkTrack = (params: LinkTrackType) => {
	const userBo = !isEmpty(user.getUserBo())
		? JSON.parse(user.getUserBo() as string)
		: {};
	permissionJpService.addContentTrackApi({
		...params,
		loginEmail: userBo?.loginEmail,
		divisionId: 1,
		event: 'view',
		country: 'JP',
	});
};

export const withHttpOrHttps = (url: string) => {
	return startsWith(url, 'http://') || startsWith(url, 'https://');
};

export const applayoutUrl = [
	'/home',
	'/notification',
	'/products',
	'/service',
	'/help',
	'/my-favorite',
	'/searchResult',
	'/my-profile',
	'/admin',
	'/contact-us',
];

export const publicUrl = [
	'/login/callback',
	'/',
	'/login-review',
	'/login-inactive',
	'/addtional-info',
	'/404',
	'/FAQ',
	'/show-file',
	'/form-inquiry-FTIR',
	'/form-inquiry-UVVIS',
	'/addtional-havebranch',
	'/addtional-notbranch',
];

export const removeScrollTopUrl = [
	'/addtional-info',
	'/addtional-havebranch',
	'/addtional-notbranch',
];

export const getDeviceType = () => {
	const userAgent = navigator.userAgent;
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		userAgent
	)
		? 'mobile'
		: 'pc';
};

export const downloadImage = (url: string, fileName: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const replaceTextWithLink = (text: string, noCollapse?: boolean) => {
	const regex = /<([^|]+)\|([^>]+)>/g;

	if (!text) return text;

	let content = text.replace(regex, (match, linkText, linkUrl) => {
		const formattedLink =
			trim(linkUrl).startsWith('http://') ||
				trim(linkUrl).startsWith('https://')
				? trim(linkUrl)
				: `http://${trim(linkUrl)}`;

		return `<a href="${formattedLink}" target="_blank">${trim(linkText)}</a>`;
	});

	if (noCollapse) {
		const visibleText = content.replace(
			/<a href="[^"]+" target="_blank">([^<]+)<\/a>/g,
			'$1'
		);

		// Count length of visible text including <a> tags
		let tagLength = 0;
		replace(content, /<a[^>]*>([^<]+)<\/a>/g, (match, text) => {
			tagLength += text.length;
			return match;
		});

		if (size(visibleText) + tagLength > 150) {
			let truncatedContent = '';
			let totalLength = 0;
			replace(
				content,
				/(<a[^>]*>([^<]+)<\/a>)|([^<>]+)/g,
				(match, aTag, aTagText, plainText) => {
					if (totalLength < 150) {
						if (aTag) {
							truncatedContent += aTag;
							totalLength += size(aTagText);
						} else if (plainText) {
							if (totalLength + size(plainText) <= 150) {
								truncatedContent += plainText;
								totalLength += size(plainText);
							} else {
								truncatedContent += plainText.slice(0, 150 - totalLength);
								totalLength = 150;
							}
						}
					}
					return match;
				}
			);

			content = truncatedContent;
		}
	}

	return content;
};

export const clickTabStyle = () => {
	return `
	 @keyframes shadowSlide {
		from {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
		to {
			box-shadow: 0 2px 0px 0 #e71316; 
		}
	}

	.clickTabStyle {
		animation: shadowSlide 0.5s ease forwards;
		z-index:20;
	}
	`;
};

export const parsingTokens = () => {
	const token = myLoginService.getGigyaToken();
	let parsedPayload: any = {};
	if (token) {
		const parts = split(token, '.');
		if (size(parts) !== 3) {
			throw new Error('Invalid token format');
		}

		const payload = parts[1];
		if (!payload) {
			throw new Error('Payload is empty');
		}

		const decodedPayload =
			atob(payload) || Buffer.from(payload, 'base64').toString('utf-8');

		try {
			parsedPayload = JSON.parse(decodedPayload);
		} catch (error) {
			parsedPayload = {};
		}
	}

	if (
		parsedPayload &&
		parsedPayload?.given_name &&
		parsedPayload?.family_name
	) {
		return `${parsedPayload?.family_name} ${parsedPayload?.given_name}`;
	} else {
		return '';
	}
};


export const addtionalSuccess = () => {
	myLoginService.setUserRoleUrl('/login-review');
	Modal.success({
		title: t('ContentManagementJp.successTip'),
		content: t('AdditionalInfo.additionSuccess'),
		okType: 'primary',
		okButtonProps: { danger: true },
		okText: t('ContentManagementJp.okBtn'),
		closable: true,
		onOk: () => {
			window.location.href = '/login-review';
		},
	});
};